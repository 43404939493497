.hide {
  display: none;
}
.creator-form {
  width: 100%;
}
.form-container {
  width: 100%;
}
.table-content {
  overflow-x: scroll;
  width: 100px;
}
.btn-right {
  float: right;
}
.pagination-content {
  float: right;
  padding-right: 20px;
}
.creator-form .ant-form-item-explain {
  display: none;
}

.tagStyle {
  margin: 0px 5px 5px 0px;
  font-size: 14px;
}
.creator-form {
  padding: 5px 0px;
}

.searchBox {
  width: 100%;
  padding-bottom: 10px;
}

.filterButton {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 5%;
  margin-bottom: 5%;
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.columnCenter {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amContentTitle {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}

.titleText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ContentTitle {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
}

.ContentPreviewModal {
  height: 60vh;
  overflow-y: auto;
  padding: 0rem 1rem;
}

.tagStyle {
  margin: 0px 5px 5px 0px;
  font-size: 14px;
}

.option_div:focus,
.option_div:active,
.option_div:hover {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
  width: 100%;
}

.image-text {
  bottom: 35px;
  left: 18px;
  width: 220px;
  position: absolute;
  color: white;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-gap: 2rem;
  justify-items: center;
  align-items: center;
}
.duplicate-content-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.content-card {
  width: 256px;
  height: 620px;
}

.text-block {
  position: absolute;
  bottom: 8px;
  left: 18px;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: white;
}

.hashtag-lang {
  position: relative;
  padding: 4px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.hashtag-lang-block {
  position: absolute;
  bottom: 60px;
  font-size: 15px;
  width: 130px;
  display: flex;
  color: white;
  margin-left: 7px;
}

/* .closeFlipped {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 20px;
  width: 95%;
} */

.statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
}

.details {
  color: dimgray;
  font-weight: 600;
}

.bar {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
}

.details-container {
  display: grid;
  grid-template-columns: 4rem 1fr 4rem;
  align-items: center;
}

@media only screen and (min-width: 726px) {
  .mobile-nav {
    display: none;
  }
}

@media only screen and (max-width: 726px) {
  .content-card {
    width: 100%;
  }

  .arrow {
    display: none;
  }

  .details-view {
    width: 87vw;
  }
}

.delete-icon {
  color: red;
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-40%, -40%);
  cursor: pointer;
  z-index: 1000;
}

.contest-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  align-items: center;
}

.contest-card {
  width: 256px;
}

@media only screen and (max-width: 726px) {
  .contest-card {
    width: 100%;
  }
}

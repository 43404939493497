.hide {
  display: none;
}
.creator-form {
  width: 100%;
}
.form-container {
  width: 100%;
}
.table-content {
  overflow-x: scroll;
  width: 100px;
}
.btn-right {
  float: right;
}
.pagination-content {
  float: right;
  padding-right: 20px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 1rem;
}

.source-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

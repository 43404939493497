.option_div:focus,
.option_div:active,
.option_div:hover {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
  width: 100%;
}

.break-word {
  word-break: break-word;
}

.image-text {
  bottom: 8px;
  left: 18px;
  width: 200px;
  position: absolute;
  color: white;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 4px;
}

.rating {
  bottom: 116px;
  left: 18px;
  position: absolute;
  color: white;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px 0px;
}

.taxonomy-rating {
  bottom: 120px;
  left: 18px;
  position: absolute;
  color: white;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px 0px;
}

/* .content-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
} */

.text-block {
  position: absolute;
  bottom: 36px;
  left: 18px;
  padding: 2px 4px;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: white;
}

.hashtag-lang {
  position: relative;
  padding: 2px 4px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.hashtag-lang-block {
  position: absolute;
  bottom: 64px;
  font-size: 15px;
  width: 130px;
  display: flex;
  color: white;
  margin-left: 6px;
}

.statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
}

.details {
  color: dimgray;
  font-weight: 600;
}

.bar {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
}

/* @media only screen and (max-width: 726px) {
 .content-card {
   width: 100%;
 }

} */

.chips_input_container {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.chips_input_container:focus,
.chips_input_container:active,
.chips_input_container:hover {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
}

.chips_input_container.has_error {
  border-color: #f5222d;
}

.chips_input_container.has_error:focus,
.chips_input_container.has_error:active,
.chips_input_container.has_error:hover {
  border-color: #f5222d;
  box-shadow: 0 0 0 2px rgba(253, 0, 0, 0.2);
}

.chips_input_container > span.chip {
  display: inline-block;
  line-height: 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  align-items: center;
  justify-content: center;
  padding: 0 0.2rem 0 0.5rem;
}

.chips_input_container > span.chip.active {
  border-color: #096dd9;
  color: #096dd9;
}

.chips_input_container > input {
  border: none;
  outline: none;
  min-width: 15rem;
}

.chips_input_container.disabled {
  border-color: #d9d9d9;
  box-shadow: none;
}

.chips_input_container.disabled,
.chips_input_container.disabled * {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}

.chips_input_container.disabled * {
  pointer-events: none;
}

.chips_input_container.disabled input {
  background: transparent;
}

.fixed_height_container {
  max-height: 6rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

span.full_width > span.ant-calendar-picker-input {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

a.ant-calendar-time-picker-btn,
a.ant-calendar-today-btn {
  color: #4670a2;
}

.color-selector {
  display: inline-block;
  margin: 0 auto;
  border-radius: 3px;
  position: relative;
  font-family: verdana;
  background: white;
}

.circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin-right: 10px;
}

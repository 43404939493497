.searchBox {
  width: 100%;
  padding-bottom: 10px;
}

.filterButton {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 5%;
  margin-bottom: 5%;
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.columnCenter {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-font {
  font-size: 15px;
}

.amContentTitle {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}

.titleText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ContentTitle {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
}

.ContentPreviewModal {
  height: 60vh;
  overflow-y: auto;
  padding: 0rem 1rem;
}

.tagStyle {
  margin: 0px 5px 5px 0px;
  font-size: 14px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
}
.grid-container2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding: 10px;
}

table tr .width20imp {
  width: 20% !important;
}
table tr .width30imp {
  width: 30% !important;
}
.grid-container-6-col {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  padding: 10px;
}

.grid-child {
  background-color: #f8f9fa;
  padding: 10px;
}

.hashtags {
  grid-column: 1 / span 2;
}

.audio {
  background-color: #f8f9fa;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.granularity {
  padding: 10px;
}

.location {
  padding: 10px;
}

.trending {
  background-color: #f8f9fa;
}

.created-by {
  background-color: #f8f9fa;
  padding: 10px;
}

.category {
  padding-left: 10px;
  padding-right: 10px;
}

.language {
  padding-left: 10px;
  padding-right: 10px;
}

.duetable {
  background-color: #f8f9fa;
  padding: 10px;
}

.target {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 500px) and (max-width: 992px) {
  .columnCenter {
    height: 100%;
    padding-bottom: 5px;
  }

  .tagStyle {
    margin-bottom: 5px;
  }

  .rejectButtonStyle {
    width: 100px;
  }
}
.thumnailImage {
  height: 100%;
  width: 100%;
  padding-right: 15px;
}

.full-width-ant-badge {
  width: 95%;
}

.mobileFilterButton {
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: #001529;
  color: white;
  right: 0;
  margin: 5%;
  margin-bottom: 5%;
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.profile {
  display: flex;
  align-items: center;
}

.user-name {
  font-size: 14px;
  font-weight: 600;
}

.user-info {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.profile-verified {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sub-details {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.data {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .thumnailImage {
    height: 100%;
    width: 100%;
    padding-right: 0px;
  }
}

.creatorTable {
  margin: auto;
  text-align: center;
  border: 1px solid black;
  width: 98%;
}
.tableRow > th {
  border: 1px solid black;
  padding: 15px;
  border-collapse: collapse;
  border-width: unset !important;
}

.tableRow > td {
  border: 1px solid black;
  padding: 15px;
  border-collapse: collapse;
  border-width: unset !important;
}
.tableRow {
  border: 1px solid black;
  padding: 15px;
  border-collapse: collapse;
  border-width: unset !important;
}
.tag-item {
  background-color: #f3f3f9;
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  height: 30px;
  padding: 0 4px 0 8px;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.add-more {
  background-color: #556ee6;
  color: white;
}

.add-more > .button {
  color: black;
}

.filter-dropdown {
  position: relative;
  display: block;
  z-index: 998 !important;
}

.filter-dropdown-content {
  position: absolute;
  top: 100%;
  background-color: white;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 100;
  border-radius: 1rem;
}

@media only screen and (max-width: 726px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .hashtags {
    grid-column: unset;
  }
}

.margin-t-b-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.fs1 {
  font-size: 1rem;
}
.display-none {
  display: none;
}

.campaign-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 2rem;
}

.example {
  background-color: #34adff;
  background-image: -webkit-linear-gradient(150deg, #ecebe7 35%, #ffffff 35%);
  min-height: 00px;
}
.table th {
  width: 50% !important;
}
.ellipsis {
  display: block;
  width: 11rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flex {
  display: flex;
}

ul.metismenu {
  padding-left: 0px !important;
}

.search-box,
.close-icon,
.search-wrapper {
  position: relative;
  padding: 10px;
}

.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.close-icon:after {
  content: "X";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: #fa9595;
  z-index: 1;
  right: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 12px;
  box-shadow: 0px 0px #e50f0f;
  cursor: pointer;
}
.search-box:not(:valid) ~ .close-icon {
  display: none;
}

.ps-submenu-content {
  background-color: #0b2948 !important;
}

.ps-menu-label {
  color: #8ba1b7 !important;
}

.ant-list-item:nth-child(odd) {
  background-color: whitesmoke;
}

.list_view {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.issue_list_container {
  background-color: white;
  box-shadow: 10px #f6f6f6;
}

.issue_list_header {
  font-weight: bold;
  font-size: x-large;
  padding: 20px;
}

.card_view {
  max-width: 500px;
  box-shadow: 5px 5px #f3eded;
  margin: 10px;
  border: 2px solid rgb(229, 170, 170);
  border-radius: 25px;
  background-color: white;
  height: 240px;
  padding: auto;
}

@media (max-width: 1900px) {
  .card_view {
    max-width: 45vh;
    box-shadow: 5px 5px #f3eded;
    margin: 10px;
    border: 2px solid rgb(229, 170, 170);
    border-radius: 25px;
    background-color: white;
    height: 240px;
    padding: auto;
  }
}

@media (max-width: 1500px) {
  .card_view {
    max-width: 100vh;
    box-shadow: 5px 5px #f3eded;
    margin: 10px;
    border: 2px solid rgb(229, 170, 170);
    border-radius: 25px;
    background-color: white;
    height: 240px;
    padding: auto;
  }
}

.card_view:hover {
  /* max-width: 500px; */
  border: 4px solid rgb(156, 167, 204);
  box-shadow: 10px 10px #dfd9d9;
  cursor: pointer;
}

.card_title {
  font-weight: bold;
  font-size: large;
  display: inline-block;
  width: 60%;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.tag {
  margin-left: 5px;
  padding: 5px;
}

.tag_container {
  display: flex;
  height: fit-content;
  margin-left: auto;
}

.tag_label {
  font-style: italic;
  font-weight: bold;
  font-size: small;
}

.issue_details {
  width: 95%;
  display: inline;
}

.icon_button {
  width: 5%;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-left: 10px;
}

.summary {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.no_data {
  margin-top: 30vh;
  font-style: italic;
  font-weight: bold;
  color: rgb(131, 121, 121);
  text-align: center;
  height: 46vh;
  font-size: medium;
}

.font_italic_bold {
  font-style: italic;
  font-weight: bold;
}

.chips {
  width: 80%;
}
.filterCss {
  white-space: nowrap;
  margin-left: -13px;
}

.add_issue_button {
  width: 144px;
  display: flex;
  margin-top: 20px;

  height: fit-content;
}

.add_issue_button_text {
  font-size: 12px;
  padding: none;
  font-weight: bold;
}

.display_flex {
  display: flex;
}
.display_flex_header {
  display: flex;
}

.display_block {
  display: block;
}

.margin_bottom_10px {
  margin-bottom: 10px;
}

.margin_left_auto {
  margin-left: auto;
}
.margin_bottom_5px {
  margin-bottom: 5px;
}
.oneLineText {
  white-space: nowrap;
  text-align: center;
}

.col-xl-4 {
  flex: 0 0 auto;
  width: 32% !important;
}

.hight_95_vh {
  height: 95vh;
}

.chart_hight {
  height: 100%;
}

.activity_hight {
  height: 54vh;
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.activity_list {
  max-height: 40vh;
  overflow: hidden;
}

.activity_list:hover {
  max-height: 40vh;
  overflow: scroll;
  overflow-x: hidden;
}

.pagination_position {
  position: relative;
  bottom: 0;
  right: 0;
}
.activity-ellipse {
  display: -webkit-box;
  max-width: 500px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.issue_btn_header {
  display: flex;
}

@media (max-width: 1200px) {
  .activity_hight {
    height: auto;
  }
  .margin_left_auto {
    margin-left: 0;
  }
  .hight_95_vh {
    height: auto;
  }
  .col-xl-4 {
    width: 100% !important;
  }
  .chart_hight {
    height: auto;
    margin-top: 20px !important;
  }
  .pagination_position {
    position: relative;
  }
  .issue_btn_header {
    display: block;
  }
  .add_issue_button {
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 7px;
  }
  .display_flex_header {
    justify-content: space-between;
  }
  .chips {
    margin-bottom: -15px;
  }
}

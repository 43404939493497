.seed-videos-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
}

.seed-video-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.1s;
  position: relative;
}

.seed-video-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
#tbl,
#tbl th,
#tbl td {
  border: 1px solid black !important;
  text-align: center;
}
.ant-modal-content {
  padding: 17px !important;
}
.seed-video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.seed-video-expand {
  position: absolute;
  top: 0;
  right: 0;
  /* transform: translate(-50%, -50%); */
}

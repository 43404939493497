.noDataChart {
  height: 422px;
  margin-top: 28px;
  font-size: 19px;
}

.chartTitle {
  text-align: center;
}

.noDataTitle {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.filter_bar {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.filter_bar > div {
  margin: 5px 20px 5px 0px;
}

.input_select {
  margin: 10px !important;
}

.display-flex {
  display: flex;
}

.agency_label {
  font-weight: bold;
  font-size: medium;
  min-width: max-content;
  padding-right: 10px;
}

.filter_btn_margin {
  margin: 0px 5px;
}

.dashboard-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 1rem;
}

.not-authorised-page {
  margin-top: 40vh;
  text-align: center;
  font-size: medium;
  font-style: italic;
  font-weight: bold;
}

.modalTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.userCardContent {
  margin: 2rem;
  cursor: pointer;
}

.hashtagCardStyle {
  display: "flex";
  justify-content: "space-around";
  padding-top: "15px";
}

.collection-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  align-items: center;
}

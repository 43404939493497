.loadingBtn {
  animation: loading1 1s ease infinite;
}

@keyframes loading1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fix-button {
  width: 67px;
  display: inline-block !important;
}

.ant-modal {
  max-width: calc(100vw - 0px) !important;
}

.margin_right_20px {
  margin-right: 20px !important;
}

.zone_infinite_scroll_list_view {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  margin-bottom: 20px;
  overflow-x: hidden;
}

.zone_infinite_scroll_list_view::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.zone_infinite_scroll_list_view:hover::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.zone_infinite_scroll_list_view::-webkit-scrollbar-track {
  /* background: orange;        color of the tracking area */
}
.zone_infinite_scroll_list_view::-webkit-scrollbar-thumb {
  background-color: rgb(229, 229, 233); /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
  /* border: 3px solid orange;  creates padding around scroll thumb */
}

.user-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  align-items: center;
}

.user-card {
  width: 262px;
}

@media only screen and (max-width: 726px) {
  .user-card {
    width: 100%;
  }
}

/* these make sure it can work in any text element */
.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

.inline-text_copy--active {
  cursor: pointer;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border-bottom: 1px solid #666666;
  text-align: left;
}

.option_div:focus,
.option_div:active,
.option_div:hover {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
  width: 100%;
}

.break-word {
  word-break: break-word;
}

.image-text {
  bottom: 8px;
  left: 18px;
  width: 200px;
  position: absolute;
  color: white;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 4px;
}

.rating {
  bottom: 116px;
  left: 18px;
  position: absolute;
  color: white;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px 0px;
}

.taxonomy-rating {
  bottom: 120px;
  left: 18px;
  position: absolute;
  color: white;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px 0px;
}

/* .content-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
} */

.text-block {
  position: absolute;
  bottom: 36px;
  left: 18px;
  padding: 2px 4px;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: white;
}

.hashtag-lang {
  position: relative;
  padding: 2px 4px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.hashtag-lang-block {
  position: absolute;
  bottom: 64px;
  font-size: 15px;
  width: 130px;
  display: flex;
  color: white;
  margin-left: 6px;
}

.statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
}

.details {
  color: dimgray;
  font-weight: 600;
}

.bar {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
}

.css-g1d714-ValueContainer {
  padding: 1px 8px !important;
}

.css-yk16xz-control {
  border-radius: 0px !important;
  min-height: 30px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 30px;
}

.preview_delete_icon {
  margin: 40px 200px;
}

.preview_image_card {
  border: 2px solid black;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  padding: 10px;
  /* width: 250px; */
  width: 150px;
  height: 120px;
}

.sl_no {
  margin-top: 10px;
  border: 1px solid blue;
  border-radius: 50%;
  background: blue;
  color: white;
  font-weight: bolder;
  width: 20px;
  height: 20px;
  text-align: center;
}

.zone_map_cards {
  position: none !important;
  justify-content: space-around !important;
}

.sibling_btn {
  border: 1px solid black;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: inline-flex;
  background-color: silver;
  width: fit-content !important;
  position: absolute !important;
  top: 30%;
  transform: translate(0%, -30%);
  cursor: pointer;
}

.zone_map_card_level {
  border: 1px solid black;
  background: yellow;
  /* border-radius: 100px; */
  display: initial;
  padding: 1px 3px !important;
}

.level_position {
  display: inline-flex;
  width: fit-content !important;
  position: absolute !important;
  top: 2%;
  left: 96%;
  transform: translate(-96%, -2%);
}

.sibling_list {
  display: grid;
  grid-template-columns: 20% 80%;
}

.zone-title-ellipse {
  display: inline-block;
  width: 95%;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.zone_meta_title {
  display: inline-block;
  width: 100%;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.card_title {
  font-weight: bold;
  font-size: large;
  display: inline-block;
  width: 60%;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.level_devider {
  width: 100%;
  height: 30px;
  border: 1px solid silver;
  background: linear-gradient(45deg, rgb(42, 48, 46), rgb(240, 11, 194));
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  font-weight: 600;
}

.zoneMapCard:hover {
  /* max-width: 500px; */
  box-shadow: 5px 5px #dfd9d9;
  cursor: pointer;
}

/* .miss_zone_id {
  font-size: medium;
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  font-style: italic;
  font-weight: 500;
  display: flex;
} */

/* @media only screen and (max-width: 726px) {
 .content-card {
   width: 100%;
 }

} */

.list-wrapper {
  background-color: rgb(255, 255, 255);
  border: 1px solid #d9d9d9;
  display: flex;
  border-radius: 3px;
  padding: 5px;
}

.list-wrapper.active {
  border-color: rgb(38, 132, 255);
}

.list-item {
  display: flex;
  border-radius: 3px;
  background-color: rgb(233, 233, 233);
  border: 1px solid rgb(173, 173, 173);
  margin-left: 3px;
}

.item-value {
  padding: 3px 8px;
  white-space: nowrap;
}

.item-remove {
  padding: 3px 8px;
  box-sizing: border-box;
}

.item-remove:hover {
  color: rgb(129, 41, 41);
  background-color: lightcoral;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}

.item-input {
  margin-left: 3px;
  border: none;
  word-break: keep-all;
  white-space: nowrap;
  box-sizing: content-box;
  width: 3px;
}

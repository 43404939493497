.actions-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.select {
  padding-right: 12px;
  padding-left: 12px;
}

.buttons {
  display: block;
  flex: unset;
  max-width: 33.33333333%;
}

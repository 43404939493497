.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 400px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 0em;
}

.fc {
  /* the calendar root */
  max-width: 1000px;
  margin: 0 auto;
}

.type-day {
  border-color: white;
  background-image: linear-gradient(
    to right,
    #4ca1af,
    #6db1bc,
    #8bc0ca,
    #a8d0d7,
    #c4e0e5
  );
}

.type-national {
  border-color: white;
  background-image: linear-gradient(
    to right,
    #f06d06,
    rgb(255, 255, 255),
    green
  );
}

.type-religion {
  border-color: white;
  background-image: linear-gradient(
    to right,
    #de6262,
    #e87869,
    #f08e72,
    #f8a37e,
    #ffb88c
  );
}

.type-date {
  border-color: white;
  background-image: linear-gradient(
    to right,
    #ddd6f3,
    #e6cbea,
    #f1bfda,
    #f9b4c3,
    #faaca8
  );
}

.type-regional {
  border-color: white;
  background-image: linear-gradient(
    to right,
    #43cea2,
    #00b5b3,
    #0099bb,
    #007ab4,
    #185a9d
  );
}

.type-rip {
  border-color: white;
  background-image: linear-gradient(
    to right,
    #bdc3c7,
    #96a0a7,
    #707e89,
    #4d5d6c,
    #2c3e50
  );
}

.type-celeb {
  border-color: white;
  background-image: linear-gradient(
    to right,
    #7b4397,
    #a1338a,
    #c01d73,
    #d40d55,
    #dc2430
  );
}

.type-sports {
  border-color: white;
  background: linear-gradient(90deg, #fcff9e 0%, #c67700 100%);
}

.type-election {
  border-color: white;
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}

.type-part-day {
  border-color: white;
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.grid-container {
  display: grid;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
}

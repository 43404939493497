/* img {
    height: 100px;
    width: 100px;
    padding: 10px;
} */

.theme {
  font-size: 20px;
  font-weight: 600;
}

.tabData {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

/* .tabData:hover {
    color: blue;
} */

.themeClick {
  font-size: 20px;
  font-weight: 600;
}

.themeClick:hover {
  color: blue;
}

.cardTab {
  display: flex;
  justify-content: space-around;
}

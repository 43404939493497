ul.striped li:nth-child(even) {
  background: #efefef;
}

.notify-badge {
  position: absolute;
  background: rgb(47, 169, 199);
  height: 2rem;
  right: -0.5rem;
  width: 2rem;
  text-align: center;
  line-height: 2rem;
  font-size: 1rem;
  border-radius: 50%;
  color: white;
  border: 1px solid rgb(228, 228, 233);
  bottom: 1rem;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s ease;
  background-color: transparent;
}

.verified-icon {
  color: limegreen;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(70%, 70%);
  cursor: pointer;
  z-index: 2;
}

.verified-icon-small {
  color: limegreen;
  font-size: 1.2rem;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(70%, 70%);
  z-index: 2;
}

.TFtable {
  width: 100%;
  border-collapse: collapse;
}
.TFtable td {
  padding: 7px;
}
.TFtable tr {
  background: #ffffff;
}
.TFtable tr:nth-child(odd) {
  background: #f8f9fa;
}
.TFtable tr:nth-child(even) {
  background: #ffffff;
}

.rt-tr-group {
  background: green;
}
.rt-tr-group:nth-of-type(even) {
  background: red;
}

.back-1 {
  background-color: darkturquoise;
}

.back-2 {
  background-color: darkorchid;
}

.creator-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-gap: 1rem;
}

.dashboard-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 1rem;
}

.filter-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
  grid-gap: 1rem;
}

.searchBox {
  width: 100%;
  padding-bottom: 10px;
}

.filterButton {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 5%;
  margin-bottom: 5%;
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.columnCenter {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amContentTitle {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}

.titleText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ContentTitle {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
}

.ContentPreviewModal {
  height: 60vh;
  overflow-y: auto;
  padding: 0rem 1rem;
}

.tagStyle {
  margin: 0px 5px 5px 0px;
  font-size: 14px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 500px) and (max-width: 992px) {
  .columnCenter {
    height: 100%;
    padding-bottom: 5px;
  }

  .tagStyle {
    margin-bottom: 5px;
  }

  .rejectButtonStyle {
    width: 100px;
  }
}
.thumnailImage {
  padding-right: 15px;
}

.full-width-ant-badge {
  width: 95%;
}

.hide {
  display: none;
}
.creator-form {
  width: 100%;
}
.form-container {
  width: 100%;
}
.table-content {
  overflow-x: scroll;
  width: 100px;
}
.btn-right {
  float: right;
}
.pagination-content {
  float: right;
  padding-right: 20px;
}
.ant-form-item-explain {
  display: none;
}

.option_div:focus,
.option_div:active,
.option_div:hover {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px !important;
  width: 100%;
}

.mobileFilterButton {
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: #001529;
  color: white;
  right: 0;
  margin: 5%;
  margin-bottom: 5%;
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

@media only screen and (max-width: 800px) {
  .thumnailImage {
    height: 100%;
    width: 100%;
    padding-right: 0px;
  }
}

.upload {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.profile-container:hover .profile-pic {
  opacity: 0.3;
}

.profile-container:hover .upload {
  opacity: 1;
}
.recharts-surface {
  height: 100% !important;
}
.noDataChart {
  height: 422px;
  margin-top: 28px;
  font-size: 19px;
}
.chartTitle {
  text-align: center;
}
.noDataTitle {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.modebar {
  display: none;
}

.timespan-btn-margin {
  margin: 5px 10px 20px 10px;
}
.btn-boxshadow {
  box-shadow: none !important;
}

.margin_10px {
  margin: 10px;
}

.z_index_10 {
  z-index: 10;
}

.contentCard {
  margin: 0.5rem;
  cursor: pointer;
}

.selectedCard {
  border: 2px solid #28a745;
  transform: scale(1.03);
}

.unSelectedCard {
  border: 2px solid #e0190b;
  transform: scale(1.03);
}

.addedCard {
  border: 2px solid #0356fa;
  transform: scale(1.03);
}

.warningCard {
  border: 2px solid #ffc107;
  transform: scale(1.03);
}

.categories {
  flex: 9;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
}

.middle-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 95vh;
}

.video-container {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  overflow-y: auto;
}

.ant-modal {
  max-width: 100%;
}

.ant-modal-wrap {
  z-index: 1000 !important;
  overflow: unset !important;
}

.ant-modal-content {
  padding: 0 !important;
}

.category {
  flex: 1;
}

.card-data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-img-top-custom {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  vertical-align: top;
}

.card-img-container {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}

.card-text-footer {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}

.stopwatch {
  color: rgb(248, 248, 248);
  font-weight: 700;
  padding: 5px 1.5rem;
  margin-right: 2px;
  margin-left: 2px;
}

.hashtag {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
}

.hashtaglabels {
  font-weight: 600;
  color: #000;
  margin-top: 5px;
}

.topicRadioBtn {
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}
.topicRadioBtn2 {
  padding: 5px;
  margin: 5px;
}

.hide {
  display: none;
}
.creator-form {
  width: 100%;
}
.form-container {
  width: 100%;
}
.table-content {
  overflow-x: scroll;
  width: 100px;
}
.btn-right {
  float: right;
}
.pagination-content {
  float: right;
  padding-right: 20px;
}
.ant-form-item-explain {
  display: none;
}

.warning-modal-title {
  color: white;
  background-color: #ff4d4f;
  font-size: 16px;
}

.font_weight_normal {
  font-weight: normal !important;
}

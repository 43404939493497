.fontSize14 {
  font-size: 14px !important;
}
.width130 {
  width: 130px;
}
.fontSize18 {
  font-size: 18px !important;
}
.ml-6 {
  margin-left: 6px;
}

@import url("https://fonts.googleapis.com/css?family=Permanent+Marker|Rock+Salt");

.App {
  font-family: sans-serif;
  text-align: center;
}

.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  background-color: #2a3042;
  flex-direction: row;
  margin-top: 5px;

  .song {
    user-select: none;
    margin: 0 20px;
    .song__title {
      text-align: left;
      margin: 0;
      color: white;
      font-family: "Permanent Marker", cursive;
      font-weight: normal;
      font-size: 3.5em;

      &:hover {
        color: greenyellow;
      }
    }

    .song__artist {
      margin: 0;
      color: white;
      font-family: "Rock Salt", cursive;
      font-weight: normal;
      font-size: 1em;

      &:hover {
        color: greenyellow;
        cursor: pointer;
      }
    }
  }

  .controls {
    flex-grow: 1;
    margin: 0 20px;
    display: contents;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .player__button {
    width: fit-content;

    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
      svg {
        color: greenyellow;
      }
    }

    svg {
      font-size: 4em;
      color: white;
    }
  }
  .bluebg {
    color: white;
    font-size: 18px;
    margin-top: 3px;
  }

  .bar {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;

    .bar__time {
      color: white;
      font-size: 16px;
    }

    .bar__progress {
      flex: 1;
      width: 80%;
      border-radius: 5px;
      margin: 0 20px;
      height: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 16px;
        width: 16px;
        border: 1.5px solid white;
        border-radius: 50%;
        background-color: orange;
      }
      .bar__progress__knob_end {
        position: relative;
        height: 16px;
        width: 16px;
      }
      .bar__progress__knob_start {
        position: relative;
        height: 16px;
        width: 16px;
      }
    }
  }
  .audioarrow {
    position: relative;
    top: -2.3px;
    color: #485ec4;
    font-size: 16px;
    z-index: 2;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }

    .bar {
      width: 90%;
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}

.timeline-list {
  overflow: auto;
}
.mt2 {
  margin-top: -2px;
}
.flex200px {
  flex: 0 0 200px;
}
.flex175px {
  flex: 0 0 181px;
}
.pb-20 {
  padding-bottom: 10px !important;
}
.ant-collapse-item {
  background-color: white;
  border-bottom: none !important;
}
.iconstyle {
  font-size: 17px;
  font-weight: 800 !important;
}
.iconstyle2 {
  font-size: 17px;
}
.green {
  color: #34dd41;
}
.red {
  color: #ef2727;
}
.ant-collapse-header {
  float: right;
  color: rgb(73, 80, 87) !important;
  font-size: 12px;
  margin-top: -38px;
}
.ant-collapse-content {
  color: rgb(73, 80, 87) !important;
  font-size: 11px;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
  padding-top: 7px !important;
}
.blue {
  color: rgb(13, 110, 253) !important;
}
.flex-modify {
  display: flex;
  margin-bottom: 3px;
}
.wd85 {
  width: 82%;
}

.searchBox {
  width: 100%;
  padding-bottom: 10px;
}

.filterButton {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 5%;
  margin-bottom: 5%;
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.columnCenter {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amContentTitle {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}

.titleText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ContentTitle {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
}

.ContentPreviewModal {
  height: 60vh;
  overflow-y: auto;
  padding: 0rem 1rem;
}

.tagStyle {
  margin: 0px 5px 5px 0px;
  font-size: 14px;
}

.dragableTabs {
  border: 1px solid #e5e5e5;
  padding: 10px;
  margin: 10px;
}

.droppableContainer {
  /* width: 314px; */
  height: 300px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
}

.cardStyle {
  padding: 12px;
  cursor: pointer;
  margin-bottom: 12px;
  /* min-width: 160px; */
  /* height: 200px; */
  /* max-width: 0; */
  /* max-width: 300px; */
  min-width: 270px;
}

@media only screen and (min-width: 500px) and (max-width: 992px) {
  .columnCenter {
    height: 100%;
    padding-bottom: 5px;
  }

  .tagStyle {
    margin-bottom: 5px;
  }

  .rejectButtonStyle {
    width: 100px;
  }
}
.thumnailImage {
  height: 100px;
  width: 100px;
  padding-right: 15px;
}

.full-width-ant-badge {
  width: 95%;
}

.mobileFilterButton {
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: #001529;
  color: white;
  right: 0;
  margin: 5%;
  margin-bottom: 5%;
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

@media only screen and (max-width: 800px) {
  .thumnailImage {
    height: 100%;
    width: 100%;
    padding-right: 0px;
  }
}

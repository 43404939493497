ul,
#myUL {
  list-style-type: none;
}

#myUL {
  padding-left: 0rem !important;
}

#myUL {
  margin: 0;
  padding: 0;
  width: 85%;
}

.caret {
  cursor: pointer;
  margin-left: -16px;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.caret::before {
  content: "\003E";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nested {
  display: none;
}

.active {
  display: block;
}

.warning-modal-title {
  color: white;
  background-color: #ff4d4f;
  font-size: 16px;
}
